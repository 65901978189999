<template>
  <v-card class="card-wrap" flat>
    <v-data-table
      class="table-scroll tp-table__row-pointer datatable px-3 py-2"
      calculate-widths
      disable-pagination
      no-data-text="Không có sản phẩm nào"
      no-results-text="Không tìm thấy kết quả phù hợp"
      :headers="headers"
      hide-default-footer
      :loading="false"
      loading-text="Đang tải dữ liệu"
      :items="purchaseOrder.options"
      item-key="id"
      @click:row="openModalPurchaseOrderProductOptions($event)"
    >
      <template v-slot:[`item.id`]="{ item }">
        {{ indexOfOptionIds(item.id) + 1 }}
      </template>

      <template v-slot:[`item.option`]="{ item }">
        <div class="my-2">
          <div class="font-weight-bold">
            {{ item.option.product_name }}
          </div>
          <div class="text-subtitle-2 black--text font-weight-light">
            <div class="my-1">
              <span
                >MPN:
                <span class="font-weight-bold black--text">
                  {{ item.option.MPN ? item.option.MPN : "Không có" }}
                </span></span
              >
              <span class="px-2">|</span>
              <span
                >SKU:
                <span class="font-weight-bold black--text">{{
                  item.option.SKU
                }}</span></span
              >
            </div>
            <div
              class="grey--text text--darken-2 tag-p__mb-0 mb-1"
              v-html="item.option.name"
            ></div>
            <ul class="gift-list black--text font-weight-light pl-0">
              <li
                class="gift-list--item"
                v-for="gift in item.gifts"
                :key="gift.id"
              >
                <v-icon class="mr-1 red--text text--accent-1" size="12px">
                  mdi-wallet-giftcard
                </v-icon>
                <span>{{
                  `${gift.quantity} ${gift.option.product_name} - ${gift.option.SKU}`
                }}</span>
              </li>
            </ul>
          </div>
        </div>
      </template>

      <template v-slot:[`item.import_price`]="{ item }">
        <div>{{ item.import_price | formatCurrency }}</div>
        <div v-if="item.has_vat" class="text-subtitle-2 font-weight-medium">
          (VAT)
        </div>
      </template>

      <template v-slot:[`item.rebates`]="{ item }">
        {{ calculateRebateTotal(item.rebates) | formatCurrency }}
      </template>

      <template v-slot:[`item.last_price`]="{ item }">
        <div>
          {{
            (item.import_price - calculateRebateTotal(item.rebates))
              | formatCurrency
          }}
        </div>
        <div class="text-subtitle-2 font-weight-medium green--text">
          {{ calculateProfit(item) }}%
        </div>
      </template>

      <template v-slot:[`item.total`]="{ item }">
        {{
          ((item.import_price - calculateRebateTotal(item.rebates)) *
            item.import_qty)
            | formatCurrency
        }}
      </template>
    </v-data-table>

    <div
      v-if="
        purchaseOrder.status !== 3 &&
          purchaseOrder.status !== 4 &&
          purchaseOrder.status !== -1
      "
      class="mx-3"
    >
      <v-btn
        block
        depressed
        x-large
        @click="openModalPurchaseOrderProductOptions"
      >
        <v-icon class="mr-2" color="grey darken-1">mdi-plus</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "STT",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Tên sản phẩm",
          align: "start",
          sortable: false,
          value: "option"
        },
        {
          text: "Số lượng",
          align: "start",
          sortable: false,
          value: "import_qty"
        },
        {
          text: "Giá nhập",
          align: "center",
          sortable: false,
          value: "import_price"
        },
        {
          text: "CKTM (₫/sp)",
          align: "center",
          sortable: false,
          value: "rebates"
        },
        {
          text: "Giá cuối",
          align: "center",
          sortable: false,
          value: "last_price"
        },
        {
          text: "Thành tiền",
          align: "center",
          sortable: false,
          value: "total"
        }
      ]
    };
  },
  computed: {
    purchaseOrder() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrder"];
    },
    optionIds() {
      return this.purchaseOrder.options.map(item => item.id);
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    calculateProfit(item) {
      const lastPrice =
        item.import_price - this.calculateRebateTotal(item.rebates);
      return Math.floor(((item.option.price - lastPrice) / lastPrice) * 100);
    },

    calculateRebateTotal(rebates) {
      let total = 0;

      rebates.forEach(item => {
        if (item.value) {
          total += item.value;
        }
      });

      return total;
    },

    indexOfOptionIds(value) {
      return this.optionIds.indexOf(value);
    },

    async openModalPurchaseOrderProductOptions(item) {
      if (item && item.id) {
        await this.$store.dispatch(
          "PURCHASE_ORDER/setPurchaseOrderOptionActiveIndex",
          this.indexOfOptionIds(item.id)
        );
        await this.$store.dispatch(
          "PURCHASE_ORDER/getPurchaseOrderOptionById",
          item.id
        );
      } else {
        await this.$store.dispatch("PURCHASE_ORDER/resetPurchaseOrderOption");
      }
      this.$modal.show({ name: "modal-purchase-order-product-options" });
    }
  }
};
</script>

<style lang="scss" scoped>
.card-wrap {
  min-height: calc(100vh - 64px - 53px - 40px - 20px - 24px);
  @media only screen and (max-width: 960px) {
    min-height: calc(100vh - 56px - 53px - 40px - 20px - 24px);
  }
}
.table-scroll {
  max-height: calc(100vh - 64px - 53px - 40px - 20px - 16px - 24px);
  overflow-y: scroll;
  @media only screen and (max-width: 960px) {
    max-height: calc(100vh - 56px - 53px - 40px - 20px - 16px - 24px);
  }
}
.item-subtitle {
  ::v-deep p {
    display: inherit;
    margin-bottom: 0;
  }
}
.gift-list {
  list-style: none;
}
</style>
