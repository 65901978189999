var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"px-5 pt-4 pb-5",attrs:{"flat":""}},[_c('div',{staticClass:"text-h6 font-weight-bold"},[_vm._v("Thông tin đặt hàng")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-bold mb-2"},[_vm._v("Giá nhập")]),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-4",staticStyle:{"width":"150px"}},[_c('tp-input-price',{attrs:{"custom-class":"text-body-1 mt-0 pt-0","dense":"","disabled":_vm.purchaseOrder.status === 3 ||
                  _vm.purchaseOrder.status === 4 ||
                  _vm.purchaseOrder.status === -1,"flat":"","hide-details":"","outlined":"","placeholder":"Giá nhập","solo":"","value":_vm.purchaseOrderOption.import_price},on:{"change":function($event){_vm.purchaseOrderOption.import_price = $event}}})],1),_c('v-radio-group',{staticClass:"mt-0 pt-0",attrs:{"dense":"","disabled":_vm.purchaseOrder.status === 3 ||
                _vm.purchaseOrder.status === 4 ||
                _vm.purchaseOrder.status === -1,"hide-details":"auto","row":""},model:{value:(_vm.purchaseOrderOption.has_vat),callback:function ($$v) {_vm.$set(_vm.purchaseOrderOption, "has_vat", $$v)},expression:"purchaseOrderOption.has_vat"}},[_c('v-radio',{attrs:{"label":"VAT","value":1}}),_c('v-radio',{attrs:{"label":"Không VAT","value":0}})],1)],1)])],1),_c('list-rebates',{staticClass:"mt-2",on:{"removeRebate":function($event){return _vm.removeRebate($event)}}}),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12","lg":"4","xl":"3"}},[_c('div',{staticClass:"font-weight-bold mb-2"},[_vm._v("Giá cuối")]),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-4",staticStyle:{"width":"150px"}},[_c('tp-input-price',{attrs:{"custom-class":"text-body-1 mt-0 pt-0","dense":"","disabled":_vm.purchaseOrder.status === 3 ||
                  _vm.purchaseOrder.status === 4 ||
                  _vm.purchaseOrder.status === -1,"flat":"","hide-details":"","outlined":"","placeholder":"Nhập giá","readonly":"","solo":"","value":_vm.lastPrice}})],1),_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.profit)+"% ")])],1)]),_c('v-col',{attrs:{"cols":"12","lg":"8","xl":"9"}},[_c('div',{staticClass:"font-weight-bold mb-2"},[_vm._v("Số lượng")]),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-3",staticStyle:{"width":"80px"}},[_c('v-text-field',{staticClass:"text-body-1",attrs:{"dense":"","disabled":_vm.purchaseOrder.status === 3 ||
                  _vm.purchaseOrder.status === 4 ||
                  _vm.purchaseOrder.status === -1,"flat":"","hide-details":"","outlined":"","placeholder":"100","solo":"","type":"number"},model:{value:(_vm.purchaseOrderOption.import_qty),callback:function ($$v) {_vm.$set(_vm.purchaseOrderOption, "import_qty", $$v)},expression:"purchaseOrderOption.import_qty"}})],1),_c('div',[_vm._v(" Trị giá: "),_c('span',{staticClass:"font-weight-medium red--text text--accent-2"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.totalValue))+" ")]),_c('span',{staticClass:"px-3"},[_vm._v("|")]),_c('span',[_vm._v("Dự kiến bán trong "),_c('span',{staticClass:"font-weight-medium"},[_vm._v("X tuần")])])])])])],1),_c('list-gifts',{staticClass:"mt-4",on:{"removeGift":function($event){return _vm.removeGift($event)}}}),(
        _vm.purchaseOrder.status !== 3 &&
          _vm.purchaseOrder.status !== 4 &&
          _vm.purchaseOrder.status !== -1
      )?_c('v-btn',{staticClass:"mt-5 btn-text--normal",attrs:{"color":"primary","depressed":"","loading":_vm.purchaseOrderStatusRequest.value ===
          'loading-updatePurchaseOrderOption'},on:{"click":function($event){_vm.purchaseOrderOption.id
          ? _vm.updatePurchaseOrderOption()
          : _vm.createPurchaseOrderOption()}}},[_vm._v(" Lưu ")]):_vm._e()],1),_c('v-card',{staticClass:"px-5 pt-4 pb-5 mt-5",attrs:{"flat":""}},[_c('list-distribution')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }