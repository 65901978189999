<template>
  <div class="px-5">
    <div class="top-bar">
      <v-tabs
        v-model="activeTab"
        background-color="white"
        class="rounded mb-5"
        slider-size="0"
        height="40px"
      >
        <v-tab
          class="tab-custom text-body-1 font-weight-medium text-none"
          active-class="primary--text"
          style="letter-spacing: 0"
          v-for="item in tabItems"
          :key="item.id"
        >
          {{ item.text }}
        </v-tab>
      </v-tabs>

      <div
        v-if="
          purchaseOrder &&
            purchaseOrder.status !== null &&
            purchaseOrder.status !== undefined
        "
        class="top-bar--status"
      >
        <v-chip
          class="font-weight-bold"
          :color="valueButtonStatus.color ? valueButtonStatus.color : 'blue'"
          small
          outlined
        >
          {{ valueButtonStatus.text ? valueButtonStatus.text : "" }}
        </v-chip>
      </div>
    </div>

    <v-tabs-items class="grey lighten-3" v-model="activeTab">
      <v-tab-item class="tab-item" key="info">
        <tab-info></tab-info>
      </v-tab-item>

      <v-tab-item class="tab-item" key="discussion">
        <tab-discussion></tab-discussion>
      </v-tab-item>

      <v-tab-item class="tab-item" key="history">
        <tab-history></tab-history>
      </v-tab-item>
    </v-tabs-items>

    <modal-purchase-order-product-option></modal-purchase-order-product-option>
  </div>
</template>

<script>
import ModalPurchaseOrderProductOption from "./components/modals/ModalPurchaseOrderProductOptions/index";
import TabDiscussion from "./components/TabDiscussion";
import TabInfo from "./components/TabInfo/index";
import TabHistory from "./components/TabHistory";

export default {
  components: {
    ModalPurchaseOrderProductOption,
    TabDiscussion,
    TabInfo,
    TabHistory
  },
  data() {
    return {
      activeTab: null,
      statusList: [
        { value: -2, text: "Kết thúc sớm", color: "blue" },
        { value: -1, text: "Hủy", color: "red" },
        { value: 0, text: "Nháp", color: "grey" },
        { value: 1, text: "Chờ duyệt", color: "purple" },
        { value: 2, text: "Đã duyệt", color: "blue" },
        { value: 3, text: "Đang nhập", color: "amber" },
        { value: 4, text: "Thành công", color: "green" }
      ],
      tabItems: [
        { id: "info", text: "Thông tin đơn hàng" },
        { id: "discussion", text: "Thảo luận" },
        { id: "history", text: "Lịch sử thay đổi" }
      ]
    };
  },
  computed: {
    purchaseOrder() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrder"];
    },
    valueButtonStatus() {
      let data = {};
      this.statusList.map(item => {
        if (item.value === this.purchaseOrder.status) {
          data.color = item.color;
          data.text = item.text;
          data.value = item.value;
        }
      });
      return data;
    }
  },
  async created() {
    const route = this.$route;

    if (route.name === "transaction_goods-receipts_purchase-orders-create") {
      await this.$store.dispatch(
        "PURCHASE_ORDER/getPurchaseOrderById",
        route.query.id
      );
    }
    if (route.name === "transaction_goods-receipts_purchase-orders-detail") {
      await this.$store.dispatch(
        "PURCHASE_ORDER/getPurchaseOrderById",
        route.params.purchaseOrderId
      );
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (
      this.$route.name ===
        "transaction_goods-receipts_purchase-orders-create" &&
      to.name !== "transaction_goods-receipts_purchase-orders-detail" &&
      this.purchaseOrder.id
    ) {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title:
            "Đơn đặt NCC sẽ không được lưu, bạn có chắc chắn muốn rời khỏi?",
          confirmBtn: {
            color: "red accent-2",
            text: "OK",
            onClickHandler: async () => {
              await this.$store.dispatch(
                "PURCHASE_ORDER/forceDeletePurchaseOrder",
                this.purchaseOrder.id
              );

              next();
            }
          },
          onCancelHandler: () => {
            next(false);
          }
        }
      });
    } else {
      next();
    }
  }
};
</script>

<style lang="scss" scoped>
.top-bar {
  position: relative;
  &--status {
    position: absolute;
    right: 12px;
    top: 8px;
  }
}
.tab-custom {
  transition: all 0.4s ease;
  &:after {
    background-color: #ccc;
    content: "";
    height: 14px;
    position: absolute;
    right: 0;
    width: 1px;
  }
  &:before {
    border-radius: 4px;
  }
  &:last-child {
    &:after {
      width: 0;
    }
  }
}
</style>
