<template>
  <div>
    <div class="d-flex align-center">
      <div class="text-h6 font-weight-bold mr-4 mb-1">Quà tặng kèm</div>
      <v-radio-group
        v-model="purchaseOrderOption.has_gift"
        class="mt-0 pt-0"
        dense
        :disabled="
          purchaseOrder.status === 3 ||
            purchaseOrder.status === 4 ||
            purchaseOrder.status === -1
        "
        hide-details="auto"
        row
      >
        <v-radio label="Không" :value="0"></v-radio>
        <v-radio label="Có" :value="1"></v-radio>
      </v-radio-group>
    </div>

    <template v-if="purchaseOrderOption.has_gift">
      <div
        v-if="
          purchaseOrder.status !== 3 &&
            purchaseOrder.status !== 4 &&
            purchaseOrder.status !== -1
        "
        class="search-option--box my-3"
      >
        <v-autocomplete
          class="text-body-1"
          clearable
          dense
          flat
          hide-details
          :items="filterSearchProductOptions"
          item-text="product_name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          no-filter
          outlined
          placeholder="Tìm SKU, tên quà tặng"
          prepend-inner-icon="mdi-magnify"
          return-object
          solo
          :search-input.sync="productSearchKey"
          @change="selectProductOption($event)"
        >
          <template v-slot:item="data">
            <v-list-item-content :title="data.item.name">
              <v-list-item-title
                class="font-weight-medium"
                v-html="data.item.product_name"
              ></v-list-item-title>
              <div class="grey--text text--darken-2 text-subtitle-2">
                <span class="font-weight-bold">{{ data.item.SKU }} - </span>
                <span class="item-subtitle" v-html="data.item.name"></span>
              </div>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </div>

      <v-row class="px-1">
        <v-col
          class="py-1 px-2"
          cols="12"
          lg="6"
          xl="4"
          v-for="(item, index) in purchaseOrderOption.gifts"
          :key="index"
        >
          <card-gift
            :gift="item"
            :gift-index="index"
            @removeGift="removeGift($event)"
          ></card-gift>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import CardGift from "./CardGift";

export default {
  components: {
    CardGift
  },
  data() {
    return {
      productSearchKey: null
    };
  },
  computed: {
    filterSearchProductOptions() {
      if (this.purchaseOrderOption.gifts.length > 0) {
        const arr = this.purchaseOrderOption.gifts.map(item => item.option.id);

        return this.searchProductOptions.filter(item => !arr.includes(item.id));
      }
      return this.searchProductOptions;
    },
    purchaseOrder() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrder"];
    },
    purchaseOrderOption() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrderOption"];
    },
    searchProductOptions() {
      return this.$store.getters["PRODUCT_OPTION/searchProductOptions"];
    }
  },
  watch: {
    productSearchKey(val) {
      if (val && val.length > 0) {
        this.$store.dispatch("PRODUCT_OPTION/searchProductOptions", {
          search: val
        });
      }
    }
  },
  methods: {
    removeGift(item) {
      this.$emit("removeGift", item);
    },

    async selectProductOption(item) {
      if (!item || !item.id) return false;

      await this.purchaseOrderOption.gifts.push({
        option: item,
        quantity: null
      });

      this.productSearchKey = null;

      await this.$store.dispatch(
        "PURCHASE_ORDER/setPurchaseOrderOption",
        this.purchaseOrderOption
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.search-option--box {
  @media (min-width: 1264px) {
    padding-right: 8px;
    width: 50%;
  }
  width: 100%;
  @media (min-width: 1904px) {
    padding-right: 8px;
    width: 33.333%;
  }
}
</style>
