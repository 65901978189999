<template>
  <div>
    <v-card class="px-5 pt-4 pb-5" flat>
      <div class="text-h6 font-weight-bold">Thông tin đặt hàng</div>

      <v-row>
        <v-col cols="12">
          <div class="font-weight-bold mb-2">Giá nhập</div>
          <div class="d-flex align-center">
            <div class="mr-4" style="width: 150px">
              <tp-input-price
                custom-class="text-body-1 mt-0 pt-0"
                dense
                :disabled="
                  purchaseOrder.status === 3 ||
                    purchaseOrder.status === 4 ||
                    purchaseOrder.status === -1
                "
                flat
                hide-details
                outlined
                placeholder="Giá nhập"
                solo
                :value="purchaseOrderOption.import_price"
                @change="purchaseOrderOption.import_price = $event"
              ></tp-input-price>
            </div>
            <v-radio-group
              v-model="purchaseOrderOption.has_vat"
              class="mt-0 pt-0"
              dense
              :disabled="
                purchaseOrder.status === 3 ||
                  purchaseOrder.status === 4 ||
                  purchaseOrder.status === -1
              "
              hide-details="auto"
              row
            >
              <v-radio label="VAT" :value="1"></v-radio>
              <v-radio label="Không VAT" :value="0"></v-radio>
            </v-radio-group>
          </div>
        </v-col>
      </v-row>

      <list-rebates
        class="mt-2"
        @removeRebate="removeRebate($event)"
      ></list-rebates>

      <v-row class="mt-1">
        <v-col cols="12" lg="4" xl="3">
          <div class="font-weight-bold mb-2">Giá cuối</div>
          <div class="d-flex align-center">
            <div class="mr-4" style="width: 150px">
              <tp-input-price
                custom-class="text-body-1 mt-0 pt-0"
                dense
                :disabled="
                  purchaseOrder.status === 3 ||
                    purchaseOrder.status === 4 ||
                    purchaseOrder.status === -1
                "
                flat
                hide-details
                outlined
                placeholder="Nhập giá"
                readonly
                solo
                :value="lastPrice"
              ></tp-input-price>
            </div>
            <v-chip class="font-weight-bold" color="primary" dark>
              {{ profit }}%
            </v-chip>
          </div>
        </v-col>

        <v-col cols="12" lg="8" xl="9">
          <div class="font-weight-bold mb-2">Số lượng</div>
          <div class="d-flex align-center">
            <div class="mr-3" style="width: 80px">
              <v-text-field
                v-model="purchaseOrderOption.import_qty"
                class="text-body-1"
                dense
                :disabled="
                  purchaseOrder.status === 3 ||
                    purchaseOrder.status === 4 ||
                    purchaseOrder.status === -1
                "
                flat
                hide-details
                outlined
                placeholder="100"
                solo
                type="number"
              ></v-text-field>
            </div>
            <div>
              Trị giá:
              <span class="font-weight-medium red--text text--accent-2">
                {{ totalValue | formatCurrency }}
              </span>
              <span class="px-3">|</span>
              <span
                >Dự kiến bán trong
                <span class="font-weight-medium">X tuần</span></span
              >
            </div>
          </div>
        </v-col>
      </v-row>

      <list-gifts class="mt-4" @removeGift="removeGift($event)"></list-gifts>

      <v-btn
        v-if="
          purchaseOrder.status !== 3 &&
            purchaseOrder.status !== 4 &&
            purchaseOrder.status !== -1
        "
        class="mt-5 btn-text--normal"
        color="primary"
        depressed
        :loading="
          purchaseOrderStatusRequest.value ===
            'loading-updatePurchaseOrderOption'
        "
        @click="
          purchaseOrderOption.id
            ? updatePurchaseOrderOption()
            : createPurchaseOrderOption()
        "
      >
        Lưu
      </v-btn>
    </v-card>

    <v-card class="px-5 pt-4 pb-5 mt-5" flat>
      <list-distribution></list-distribution>
    </v-card>
  </div>
</template>

<script>
import ListDistribution from "./components/ListDistribution";
import ListGifts from "./components/ListGifts";
import ListRebates from "./components/ListRebates";

export default {
  components: {
    ListDistribution,
    ListGifts,
    ListRebates
  },
  data() {
    return {
      removedGifts: [],
      removedRebates: []
    };
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  computed: {
    lastPrice() {
      if (this.purchaseOrderOption.import_price) {
        return (
          this.purchaseOrderOption.import_price -
          this.purchaseOrderOptionRebateTotal
        );
      }
      return null;
    },
    profit() {
      if (this.lastPrice) {
        return Math.floor(
          ((this.purchaseOrderOption.option.price - this.lastPrice) /
            this.lastPrice) *
            100
        );
      }
      return 0;
    },
    purchaseOrder() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrder"];
    },
    purchaseOrderOption() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrderOption"];
    },
    purchaseOrderOptionRebateTotal() {
      return this.$store.getters[
        "PURCHASE_ORDER/purchaseOrderOptionRebateTotal"
      ];
    },
    purchaseOrderStatusRequest() {
      return this.$store.getters["PURCHASE_ORDER/statusRequest"];
    },
    totalValue() {
      if (this.lastPrice && this.purchaseOrderOption.import_qty) {
        return this.lastPrice * this.purchaseOrderOption.import_qty;
      }
      return 0;
    }
  },
  methods: {
    async createPurchaseOrderOption() {
      const objectSender = JSON.parse(JSON.stringify(this.purchaseOrderOption));

      // Convert rebates value
      const rebates = {
        add: objectSender.rebates.filter(item => !item.id),
        edit: objectSender.rebates.filter(item => item.id),
        delete: this.removedRebates
      };
      objectSender.commit_rebates = rebates;

      // Convert gifts value
      const gifts = objectSender.gifts.map(item => {
        return {
          id: item.id ? item.id : undefined,
          option_id: item.option.id,
          quantity: item.quantity
        };
      });
      objectSender.gifts = {
        add: gifts.filter(item => !item.id),
        edit: gifts.filter(item => item.id),
        delete: this.removedGifts
      };

      // Request create
      await this.$store.dispatch(
        "PURCHASE_ORDER/createPurchaseOrderOption",
        objectSender
      );
      // Alert
      if (
        this.purchaseOrderStatusRequest.value ===
        "success-createPurchaseOrderOption"
      ) {
        this.removedGifts = [];
        this.removedRebates = [];

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu thông tin đặt hàng"
          }
        });
      } else if (
        this.purchaseOrderStatusRequest.value ===
        "error-createPurchaseOrderOption"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.purchaseOrderStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    removeGift(item) {
      this.removedGifts.push(item);
    },

    removeRebate(item) {
      this.removedRebates.push(item);
    },

    async updatePurchaseOrderOption() {
      const objectSender = JSON.parse(JSON.stringify(this.purchaseOrderOption));

      // Convert rebates value
      const rebates = {
        add: objectSender.rebates.filter(item => !item.id),
        edit: objectSender.rebates.filter(item => item.id),
        delete: this.removedRebates
      };
      objectSender.commit_rebates = rebates;

      // Convert gifts value
      const gifts = objectSender.gifts.map(item => {
        return {
          id: item.id ? item.id : undefined,
          option_id: item.option.id,
          quantity: item.quantity
        };
      });
      objectSender.gifts = {
        add: gifts.filter(item => !item.id),
        edit: gifts.filter(item => item.id),
        delete: this.removedGifts
      };

      // Request update
      await this.$store.dispatch(
        "PURCHASE_ORDER/updatePurchaseOrderOption",
        objectSender
      );

      // Alert
      if (
        this.purchaseOrderStatusRequest.value ===
        "success-updatePurchaseOrderOption"
      ) {
        this.removedGifts = [];
        this.removedRebates = [];

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu cập nhật thông tin đặt hàng"
          }
        });
      } else if (
        this.purchaseOrderStatusRequest.value ===
        "error-updatePurchaseOrderOption"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.purchaseOrderStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    }
  }
};
</script>
