<template>
  <div>
    <div class="text-h6 font-weight-bold">Tình trạng tồn kho</div>
    <v-row class="row-stock py-1">
      <v-col class="py-1 px-1">
        <div class="col-stock--item green lighten-5 rounded-lg pa-2">
          <div class="text-center col-stock--label">Sẵn bán</div>
          <div class="col-stock--value">05</div>
        </div>
      </v-col>
      <v-col class="py-1 px-1">
        <div class="col-stock--item blue lighten-5 rounded-lg pa-2">
          <div class="text-center col-stock--label">Đang về</div>
          <div class="col-stock--value">06</div>
        </div>
      </v-col>
      <v-col class="py-1 px-1">
        <div class="col-stock--item red lighten-5 rounded-lg pa-2">
          <div class="text-center col-stock--label">Hư hỏng</div>
          <div class="col-stock--value">02</div>
        </div>
      </v-col>
      <v-col class="py-1 px-1">
        <div class="col-stock--item orange lighten-5 rounded-lg pa-2">
          <div class="text-center col-stock--label">Đã bảo hành</div>
          <div class="col-stock--value">01</div>
          <div class="col-stock--label">(300.000đ)</div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.row-stock {
  margin-left: -4px;
  margin-right: -4px;
}
.col-stock {
  &--item {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  &--label {
    font-size: 0.875rem;
    font-weight: 500;
  }
  &--value {
    font-size: 1.0125rem;
    font-weight: 600;
  }
}
</style>
