<template>
  <div>
    <div class="text-h6 font-weight-bold mb-2">Thị trường</div>
    <div class="market-list">
      <a
        class="market-list--item"
        v-for="(item, index) in links"
        :key="index"
        :href="item.link"
        :title="item.link"
      >
        <v-hover v-slot:default="{ hover }">
          <v-card
            class="market-list--card px-4 py-3"
            :class="
              hover
                ? 'grey lighten-5 primary--text font-weight-medium'
                : 'grey lighten-4'
            "
            flat
          >
            <div class="item-link mb-1">{{ item.link }}</div>
            <div class="item-price">
              Giá: {{ item.price | formatCurrency }} ({{ item.percent }})
            </div>
          </v-card>
        </v-hover>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          link:
            "https://www.thegioididong.com/dtdd/samsung-galaxy-note-20-ultra-5g-trang",
          price: 19990000,
          percent: "Cao 5%"
        }
      ]
    };
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  }
};
</script>

<style lang="scss" scoped>
.market-list {
  &--item {
    text-decoration: none;
    .item-link {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
    }
  }
  &--card {
    transition: all 0.4s ease;
  }
}
</style>
