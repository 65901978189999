<template>
  <div>
    <div class="text-h6 font-weight-bold mb-2">Thông tin sản phẩm</div>
    <v-card class="grey lighten-4 px-4 py-3" flat>
      <div class="mb-1">
        <span class="font-weight-bold">Tên sản phẩm: </span>
        <span>{{ purchaseOrderOption.option.product_name }} - </span>
        <span
          class="tag-p__mb-0"
          v-html="purchaseOrderOption.option.name"
        ></span>
      </div>
      <div class="mb-1">
        <span class="font-weight-bold">SKU: </span>
        <span>{{ purchaseOrderOption.option.SKU }}</span>
        <span class="px-2">|</span>
        <span class="font-weight-bold">MPN: </span>
        <span>{{
          purchaseOrderOption.option.MPN
            ? purchaseOrderOption.option.MPN
            : "Không có"
        }}</span>
      </div>
      <div class="mb-1">
        <span class="font-weight-bold">Giá bán: </span>
        <span>{{ purchaseOrderOption.option.price | formatCurrency }}</span>
      </div>
      <div class="mb-1">
        <span class="font-weight-bold">Loại hàng: </span>
        <span>{{ "Chưa có dữ liệu" }}</span>
      </div>
      <div>
        <span class="font-weight-bold">Vòng đời: </span>
        <span>{{ "Chưa có dữ liệu" }}</span>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  computed: {
    purchaseOrderOption() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrderOption"];
    }
  }
};
</script>
