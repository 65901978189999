<template>
  <tp-modal fullscreen name="modal-purchase-order-product-options">
    <v-card class="grey lighten-3" flat>
      <!-- Start: Modal toolbar -->
      <modal-toolbar
        @updateActiveProductOption="updateActiveProductOption($event)"
      ></modal-toolbar>
      <!-- End: Modal toolbar -->
      <!-- Start: Option list -->
      <div class="mx-5" v-if="purchaseOrder.options.length > 0">
        <div class="options-list pt-5 pr-3">
          <v-hover
            v-slot:default="{ hover }"
            v-for="(item, index) in purchaseOrder.options"
            :key="index"
          >
            <v-badge
              class="options-list--item"
              color="error"
              icon="mdi-trash-can-outline"
              overlap
              :value="purchaseOrderOptionActiveIndex === index ? hover : null"
            >
              <v-icon
                slot="badge"
                @click="deletePurchaseOrderOption(item, index)"
              >
                mdi-trash-can-outline
              </v-icon>
              <v-chip
                class="option-chip font-weight-medium white rounded-lg px-4 py-2"
                :class="{
                  'cyan lighten-4': purchaseOrderOptionActiveIndex === index
                }"
                @click="changeActiveOption(item, index)"
              >
                {{ item.option.SKU }}
              </v-chip>
            </v-badge>
          </v-hover>
        </div>
      </div>
      <!-- End: Option list -->
      <!-- Start: Modal body -->
      <v-row class="mx-0 my-5">
        <template v-if="purchaseOrderOption.option_id">
          <!-- Start: SKU info -->
          <v-col class="px-5 py-0" cols="5">
            <div class="col-scrollable">
              <col-info class="fill-height"></col-info>
            </div>
          </v-col>
          <!-- End: SKU info -->
          <!-- Start: Product info -->
          <v-col class="pl-0 pr-5 py-0" cols="7">
            <div class="col-scrollable">
              <col-form class="fill-height"></col-form>
            </div>
          </v-col>
          <!-- Start: Product info -->
        </template>

        <template v-else>
          <v-col class="px-5" cols="12">
            <v-card flat>
              <v-card-title class="justify-center font-weight-light grey--text">
                Chưa có sản phẩm nào.
              </v-card-title>
            </v-card>
          </v-col>
        </template>
      </v-row>
      <!-- End: Modal body -->
    </v-card>
  </tp-modal>
</template>

<script>
import ColForm from "./components/ColForm/index";
import ColInfo from "./components/ColInfo/index";
import ModalToolbar from "./components/ModalToolbar";

export default {
  components: {
    ColForm,
    ColInfo,
    ModalToolbar
  },
  computed: {
    purchaseOrder() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrder"];
    },
    purchaseOrderOption() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrderOption"];
    },
    purchaseOrderOptionActiveIndex() {
      return this.$store.getters[
        "PURCHASE_ORDER/purchaseOrderOptionActiveIndex"
      ];
    },
    purchaseOrderStatusRequest() {
      return this.$store.getters["PURCHASE_ORDER/statusRequest"];
    }
  },
  methods: {
    async changeActiveOption(item, index) {
      if (item.id) {
        await this.$store.dispatch(
          "PURCHASE_ORDER/getPurchaseOrderOptionById",
          item.id
        );
      } else {
        const objectSender = {
          option_id: item.option.id,
          ...item
        };
        await this.$store.dispatch(
          "PURCHASE_ORDER/setPurchaseOrderOption",
          objectSender
        );
      }

      // Update active index
      await this.$store.dispatch(
        "PURCHASE_ORDER/setPurchaseOrderOptionActiveIndex",
        index
      );
    },

    async deletePurchaseOrderOption(item, index) {
      if (item.id) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span>Xóa SKU <strong>${item.option.SKU}</strong></span>`,
            message:
              "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
            confirmBtn: {
              color: "red accent-2",
              text: "Xóa",
              onClickHandler: async () => {
                await this.$store.dispatch(
                  "PURCHASE_ORDER/deletePurchaseOrderOption",
                  item.id
                );
                if (
                  this.purchaseOrderStatusRequest.value ===
                  "success-deletePurchaseOrderOption"
                ) {
                  this.purchaseOrder.options.splice(index, 1);
                  await this.$store.dispatch(
                    "PURCHASE_ORDER/resetPurchaseOrderOption"
                  );
                  // Alert
                  this.$toast.show({
                    name: "toast-action-alert",
                    payload: {
                      message: "Đã xóa thành công"
                    }
                  });
                }
              }
            }
          }
        });
      } else {
        this.purchaseOrder.options.splice(index, 1);
      }
    },

    updateActiveProductOption(index) {
      this.$store.dispatch(
        "PURCHASE_ORDER/setPurchaseOrderOptionActiveIndex",
        index
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.options {
  &-list {
    display: flex;
    max-width: 100%;
    overflow: scroll;
    &--item {
      cursor: pointer;
      margin-left: 12px;
      &:first-child {
        margin-left: 0 !important;
      }
      .option-chip {
        cursor: pointer;
        height: 36px;
      }
    }
  }
}
.col-scrollable {
  height: calc(100vh - 56px - 76px - 20px);
  max-height: calc(100vh - 56px - 76px - 20px);
  overflow-y: scroll;
}
</style>
