var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-badge',{staticClass:"badge-custom d-block",attrs:{"color":"none","dot":_vm.purchaseOrder.status === 3 ||
        _vm.purchaseOrder.status === 4 ||
        _vm.purchaseOrder.status === -1,"overlap":"","offset-x":"24px","offset-y":"45%","value":hover},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-btn',{staticClass:"white btn-rebate--remove",attrs:{"color":"grey darken-2","icon":""},on:{"click":function($event){return _vm.remove()}}},[_c('v-icon',{attrs:{"size":"20px"}},[_vm._v("mdi-trash-can-outline")])],1)]},proxy:true}],null,true)},[_c('v-card',{staticClass:"grey lighten-4 pt-2 pb-5 px-4",attrs:{"flat":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"4"}},[_c('v-select',{staticClass:"text-body-1",attrs:{"flat":"","dense":"","disabled":_vm.purchaseOrder.status === 3 ||
                _vm.purchaseOrder.status === 4 ||
                _vm.purchaseOrder.status === -1,"items":_vm.rebateTypeList,"item-text":"text","item-value":"id","menu-props":{ nudgeTop: '-28px' },"placeholder":"Đối tác","hide-details":"auto","single-line":""},model:{value:(_vm.rebateType),callback:function ($$v) {_vm.rebateType=$$v},expression:"rebateType"}})],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"8"}},[(_vm.rebateType === 2)?_c('v-autocomplete',{staticClass:"text-body-1",attrs:{"clearable":"","dense":"","disabled":_vm.purchaseOrder.status === 3 ||
                _vm.purchaseOrder.status === 4 ||
                _vm.purchaseOrder.status === -1,"flat":"","items":_vm.brands,"item-text":"name","item-value":"id","hide-details":"","no-data-text":"Không có dữ liệu","placeholder":"Chọn hãng"},model:{value:(_vm.rebate.brand_id),callback:function ($$v) {_vm.$set(_vm.rebate, "brand_id", $$v)},expression:"rebate.brand_id"}}):_c('v-autocomplete',{staticClass:"text-body-1",attrs:{"clearable":"","dense":"","disabled":_vm.purchaseOrder.status === 3 ||
                _vm.purchaseOrder.status === 4 ||
                _vm.purchaseOrder.status === -1,"flat":"","items":_vm.suppliers,"item-text":"code","item-value":"id","hide-details":"","no-data-text":"Không có dữ liệu","placeholder":"Chọn nhà cung cấp"},model:{value:(_vm.rebate.supplier_id),callback:function ($$v) {_vm.$set(_vm.rebate, "supplier_id", $$v)},expression:"rebate.supplier_id"}})],1)],1),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"4"}},[_c('tp-input-price',{attrs:{"custom-class":"text-body-1 pt-0","dense":"","disabled":_vm.purchaseOrder.status === 3 ||
                _vm.purchaseOrder.status === 4 ||
                _vm.purchaseOrder.status === -1,"flat":"","hide-details":"","suffix":"₫/sp","value":_vm.rebate.value},on:{"change":function($event){_vm.rebate.value = $event}}})],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"text-body-1",attrs:{"flat":"","dense":"","disabled":_vm.purchaseOrder.status === 3 ||
                _vm.purchaseOrder.status === 4 ||
                _vm.purchaseOrder.status === -1,"placeholder":"Ghi chú","hide-details":"auto","single-line":""},model:{value:(_vm.rebate.note),callback:function ($$v) {_vm.$set(_vm.rebate, "note", $$v)},expression:"rebate.note"}})],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }