<template>
  <v-card class="card-wrap px-5 py-4" flat>
    <div>
      <div class="mb-1">
        <span class="font-weight-bold">Người tạo: </span>
        <span>{{
          purchaseOrder.id ? purchaseOrder.created_by_user.name : user.name
        }}</span>
      </div>
      <div class="mb-1">
        <span class="font-weight-bold">Ngày tạo: </span>
        <span>{{
          purchaseOrder.id ? purchaseOrder.created_at : getToday()
        }}</span>
      </div>
    </div>

    <v-divider class="my-4"></v-divider>

    <div>
      <div class="">
        <div class="font-weight-bold mb-2">Nhà cung cấp</div>
        <div class="d_flex align-center px-0">
          <v-autocomplete
            v-model="purchaseOrder.supplier"
            class="text-body-1 mr-3"
            clearable
            dense
            :disabled="
              purchaseOrder.status === 3 ||
                purchaseOrder.status === 4 ||
                purchaseOrder.status === -1
            "
            flat
            hide-details
            :items="suppliers"
            item-text="label"
            item-value="id"
            :menu-props="{ maxWidth: '332px' }"
            no-data-text="Không có dữ liệu"
            placeholder="Tìm nhà cung cấp"
            prepend-inner-icon="mdi-magnify"
            return-object
            solo
            outlined
          >
          </v-autocomplete>
        </div>
      </div>

      <v-card
        v-if="purchaseOrder.supplier"
        class="grey lighten-4 pa-4 py-3 mt-4"
        flat
      >
        <div class="d-flex flex-row align-center justify-space-between">
          <div class="mr-3">Công nợ</div>
          <div class="font-weight-bold">
            {{ purchaseOrder.supplier.cong_no | formatCurrency }}
          </div>
        </div>
        <div class="d-flex flex-row align-center justify-space-between mt-2">
          <div class="mr-3">Hạn mức còn lại</div>
          <div class="font-weight-bold">
            {{ "N/A" }}
          </div>
        </div>
        <div class="d-flex flex-row justify-space-between mt-2">
          <div class="mr-3">
            <div>Thanh toán gần nhất</div>
            <div class="text-subtitle-2 font-weight-light">(Còn X ngày)</div>
          </div>
          <div class="font-weight-bold">
            {{ "N/A" }}
          </div>
        </div>
        <div class="d-flex flex-row align-center justify-space-between mt-2">
          <div class="font-weight-medium mr-3">Tài khoản chiết khấu</div>
          <div class="font-weight-bold">
            {{ "N/A" }}
          </div>
        </div>
      </v-card>
    </div>

    <v-divider class="my-4"></v-divider>

    <div class="d-flex flex-row align-center justify-space-between">
      <div class="font-weight-bold">Giá trị đơn dự kiến</div>
      <div class="text-h6 red--text text--accent-2">
        {{ purchaseOrderValue | formatCurrency }}
      </div>
    </div>

    <div class="d-flex flex-row align-center justify-space-between mt-4">
      <div class="font-weight-bold">Ngày về dự kiến</div>
      <div>
        {{ purchaseOrderEstDate }}
      </div>
    </div>

    <div class="d-flex flex-row align-center justify-space-between mt-4">
      <div class="font-weight-bold">Hạn thanh toán</div>
      <div class="d-flex align-center" style="width: 90px">
        <v-text-field
          v-model="purchaseOrder.pay_deadline"
          class="text-body-1"
          background-color="white"
          dense
          :disabled="
            purchaseOrder.status === 3 ||
              purchaseOrder.status === 4 ||
              purchaseOrder.status === -1
          "
          filled
          hide-details
          outlined
          placeholder="15"
          single-line
          suffix="ngày"
          type="number"
        ></v-text-field>
      </div>
    </div>

    <div class="mt-5">
      <!-- Start: Save draft -->
      <v-btn
        v-if="
          purchaseOrder.status !== 3 &&
            purchaseOrder.status !== 4 &&
            purchaseOrder.status !== -2 &&
            purchaseOrder.status !== -1
        "
        block
        class="btn-submit"
        color="primary"
        dark
        depressed
        large
        :loading="
          purchaseOrder.action === 0 &&
            purchaseOrderStatusRequest.value === 'loading-updatePurchaseOrder'
        "
        @click="updatePurchaseOrder(0)"
      >
        Lưu nháp
      </v-btn>
      <!-- End: Save draft -->
      <!-- Start: Send request -->
      <v-btn
        v-if="
          purchaseOrder.status !== 3 &&
            purchaseOrder.status !== 4 &&
            purchaseOrder.status !== -2 &&
            purchaseOrder.status !== -1
        "
        block
        class="btn-submit mt-2"
        color="primary"
        dark
        depressed
        large
        :loading="
          purchaseOrder.action === 1 &&
            purchaseOrderStatusRequest.value === 'loading-updatePurchaseOrder'
        "
        @click="updatePurchaseOrder(1)"
      >
        Lưu và gửi đề xuất
      </v-btn>
      <!-- End: Send request -->
      <!-- Start: Confirm -->
      <v-btn
        v-if="purchaseOrder.status === 1"
        block
        class="btn-submit mt-2"
        color="primary"
        dark
        depressed
        large
        :loading="
          purchaseOrder.action === 2 &&
            purchaseOrderStatusRequest.value === 'loading-updatePurchaseOrder'
        "
        @click="updatePurchaseOrder(2)"
      >
        Duyệt đơn
      </v-btn>
      <!-- End: Confirm -->
      <!-- Start: Processing -->
      <v-btn
        v-if="purchaseOrder.status === 2"
        block
        class="btn-submit mt-2"
        color="primary"
        dark
        depressed
        large
        :loading="
          purchaseOrder.action === 3 &&
            purchaseOrderStatusRequest.value === 'loading-updatePurchaseOrder'
        "
        @click="updatePurchaseOrder(3)"
      >
        Tiến hành nhập
      </v-btn>
      <!-- End: Processing -->
      <!-- Start: Cancel -->
      <v-btn
        v-if="
          purchaseOrder.status !== -1 &&
            purchaseOrder.status !== 0 &&
            purchaseOrder.status !== -2 &&
            purchaseOrder.status !== 4
        "
        block
        class="btn-submit mt-2 grey--text text--darken-2"
        color="grey lighten-2"
        depressed
        large
        :loading="
          purchaseOrder.action === 4 &&
            purchaseOrderStatusRequest.value === 'loading-updatePurchaseOrder'
        "
        @click="cancelPurchaseOrder"
      >
        Hủy đơn
      </v-btn>
      <!-- End: Cancel -->
      <v-btn
        v-if="purchaseOrder.status === 3"
        block
        class="btn-submit mt-2 white--text"
        color="lighten-2 teal lighten-2"
        depressed
        large
        :loading="
          purchaseOrder.action === 4 &&
            purchaseOrderStatusRequest.value === 'loading-updatePurchaseOrder'
        "
        @click="finishPurchaseOrder"
      >
        Kết thúc sớm
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  computed: {
    purchaseOrder() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrder"];
    },
    purchaseOrderEstDate() {
      let dateArr = [];

      this.purchaseOrder.options.forEach(option => {
        if (option.assignments && option.assignments.length > 0) {
          option.assignments.forEach(item => {
            if (item.est_date) {
              dateArr.push(item.est_date);
            }
          });
        }
      });

      dateArr = this.sortDateAscending(dateArr);

      if (dateArr.length > 0) {
        if (dateArr.length === 1) {
          return `${this.formatDate(dateArr[0])}`;
        } else {
          return `${this.formatDate(dateArr[0])} - ${this.formatDate(
            dateArr[dateArr.length - 1]
          )}`;
        }
      } else {
        return "Chưa có dữ liệu";
      }
    },
    purchaseOrderStatusRequest() {
      return this.$store.getters["PURCHASE_ORDER/statusRequest"];
    },
    purchaseOrderValue() {
      let total = 0;

      this.purchaseOrder.options.forEach(option => {
        if (option.import_price && option.import_qty) {
          total +=
            (parseInt(option.import_price) -
              this.calculatePurchaseOrderOptionRebate(option)) *
            parseInt(option.import_qty);
        }
      });

      return total;
    },
    suppliers() {
      return this.$store.getters["SUPPLIER/allSuppliers"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  created() {
    this.$store.dispatch("SUPPLIER/getAllSuppliers", "?sup_cate_ids=1");
  },
  methods: {
    async cancelPurchaseOrder() {
      this.purchaseOrder.action = 4;

      const objectSender = {
        id: this.purchaseOrder.id,
        action: this.purchaseOrder.action,
        supplier_id: this.purchaseOrder.supplier.id,
        pay_deadline: this.purchaseOrder.pay_deadline
          ? this.purchaseOrder.pay_deadline
          : 1
      };

      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Hủy đơn đặt NCC <strong>PO${this.purchaseOrder.id}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          cancelBtnText: "Bỏ qua",
          confirmBtn: {
            color: "red accent-2",
            text: "Tiếp tục hủy",
            onClickHandler: async () => {
              await this.$store.dispatch(
                "PURCHASE_ORDER/updatePurchaseOrder",
                objectSender
              );
              if (
                this.purchaseOrderStatusRequest.value ===
                "success-updatePurchaseOrder"
              ) {
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã hủy P/O"
                  }
                });
              }
            }
          }
        }
      });
    },
    async finishPurchaseOrder() {
      this.purchaseOrder.action = 5;

      const objectSender = {
        id: this.purchaseOrder.id,
        action: this.purchaseOrder.action,
        supplier_id: this.purchaseOrder.supplier.id,
        pay_deadline: this.purchaseOrder.pay_deadline
          ? this.purchaseOrder.pay_deadline
          : 1
      };

      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Kết thức sớm đơn đặt NCC <strong>PO${this.purchaseOrder.id}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          cancelBtnText: "Bỏ qua",
          confirmBtn: {
            color: "red accent-2",
            text: "Tiếp tục",
            onClickHandler: async () => {
              await this.$store.dispatch(
                "PURCHASE_ORDER/updatePurchaseOrder",
                objectSender
              );
              if (
                this.purchaseOrderStatusRequest.value ===
                "success-updatePurchaseOrder"
              ) {
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã kết thúc sớm P/O"
                  }
                });
              } else {
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã có lỗi xảy ra. Vui lòng kiểm tra lại!"
                  }
                });
              }
            }
          }
        }
      });
    },

    calculatePurchaseOrderOptionRebate(option) {
      if (option.rebates.length > 0) {
        let total = 0;

        option.rebates.forEach(item => {
          if (item.value) {
            total += item.value;
          }
        });

        return total;
      } else {
        return 0;
      }
    },

    formatDate(date) {
      if (!date) return null;

      const dateTime = new Date(date),
        day = dateTime
          .getDate()
          .toString()
          .padStart(2, "0"),
        month = (dateTime.getMonth() + 1).toString().padStart(2, "0"),
        year = dateTime.getFullYear();

      return `${day}/${month}/${year}`;
    },

    getToday() {
      const date = new Date(),
        hour = date
          .getHours()
          .toString()
          .padStart(2, "0"),
        mins = date
          .getMinutes()
          .toString()
          .padStart(2, "0"),
        dd = date
          .getDate()
          .toString()
          .padStart(2, "0"),
        mm = (date.getMonth() + 1).toString().padStart(2, "0"),
        yyyy = date.getFullYear();

      return `${hour}:${mins} ${dd}/${mm}/${yyyy}`;
    },

    openModalSupplier() {
      this.$modal.show({ name: "modal-supplier" });
    },

    sortDateAscending(arr) {
      return arr.sort(function(a, b) {
        const valA = new Date(a);
        const valB = new Date(b);

        if (valA < valB) {
          return -1;
        }
        if (valA > valB) {
          return 1;
        }
        return 0;
      });
    },

    async updatePurchaseOrder(action) {
      this.purchaseOrder.action = action;

      const objectSender = JSON.parse(JSON.stringify(this.purchaseOrder));

      if (
        !objectSender.supplier ||
        (objectSender.supplier && !objectSender.supplier.id)
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Vui lòng nhập chọn nhà cung cấp"
          }
        });
      } else {
        objectSender.supplier_id = objectSender.supplier.id;
        // Request
        await this.$store.dispatch(
          "PURCHASE_ORDER/updatePurchaseOrder",
          objectSender
        );
      }

      // Alert
      if (
        this.purchaseOrderStatusRequest.value === "success-updatePurchaseOrder"
      ) {
        if (
          this.$route.name ===
          "transaction_goods-receipts_purchase-orders-create"
        ) {
          await this.$router.replace({
            name: "transaction_goods-receipts_purchase-orders-detail",
            params: {
              purchaseOrderId: this.purchaseOrder.id
            }
          });
        }

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message:
              action === 0
                ? "Đã lưu nháp P/O"
                : action === 1
                ? "Đã lưu và gửi đề xuất"
                : action === 2
                ? "Đã duyệt P/O"
                : "Đã cập nhật trạng thái Đang nhập"
          }
        });
      } else if (
        this.purchaseOrderStatusRequest.value === "error-updatePurchaseOrder"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.purchaseOrderStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card-wrap {
  min-height: calc(100vh - 64px - 53px - 40px - 20px - 24px);
  @media only screen and (max-width: 960px) {
    min-height: calc(100vh - 56px - 53px - 40px - 20px - 24px);
  }
}
.btn-submit {
  font-size: 1.0125rem;
}
</style>
