var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"grey lighten-4 fill-height px-4 py-3",attrs:{"flat":""}},[_c('div',{staticClass:"d_flex align-end justify-space-between"},[_c('div',{staticClass:"font-weight-medium mr-1"},[_vm._v("Mã kho:")]),_c('div',{staticClass:"d-flex align-end"},[(_vm.selectedBranch)?_c('div',{staticClass:"white rounded px-2 mr-2"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"grey","size":"14px"}},[_vm._v("mdi-map-marker")]),_c('span',{staticClass:"text-subtitle-2 font-weight-light"},[_vm._v(" "+_vm._s(_vm.selectedBranch.tinh_thanhpho)+" ")])],1):_vm._e(),_c('div',{staticStyle:{"width":"120px"}},[_c('v-select',{staticClass:"text-body-1",attrs:{"flat":"","dense":"","disabled":_vm.purchaseOrder.status === 3 ||
              _vm.purchaseOrder.status === 4 ||
              _vm.purchaseOrder.status === -1,"items":_vm.branches,"item-text":"name","item-value":"id","menu-props":{ nudgeTop: '-28px' },"no-data-text":"Không có dữ liệu","placeholder":"Chọn kho","hide-details":"auto","single-line":""},model:{value:(_vm.distributionItem.branch_id),callback:function ($$v) {_vm.$set(_vm.distributionItem, "branch_id", $$v)},expression:"distributionItem.branch_id"}})],1)])]),_c('div',{staticClass:"d-flex align-end justify-space-between mt-2"},[_c('div',{staticClass:"font-weight-medium mr-1"},[_vm._v("Ngày về dự kiến:")]),_c('div',[_c('tp-input-date',{attrs:{"custom-class":"text-body-1 mt-0","custom-style":"width: 80px","dense":"","disabled":_vm.purchaseOrder.status === 4 || _vm.purchaseOrder.status === -1,"hide-details":"","placeholder":"Chọn ngày","value":_vm.distributionItem.est_date},on:{"change":function($event){_vm.distributionItem.est_date = $event}}})],1)]),_c('div',{staticClass:"d-flex align-end justify-space-between mt-2"},[_c('div',{staticClass:"font-weight-medium mr-1"},[_vm._v("Số lượng hàng:")]),_c('div',{staticStyle:{"width":"40px"}},[_c('v-text-field',{staticClass:"text-body-1 text-field__text-right",attrs:{"dense":"","disabled":_vm.purchaseOrder.status === 3 ||
            _vm.purchaseOrder.status === 4 ||
            _vm.purchaseOrder.status === -1,"flat":"","hide-details":"","placeholder":"1000","type":"number"},model:{value:(_vm.distributionItem.product_qty),callback:function ($$v) {_vm.$set(_vm.distributionItem, "product_qty", $$v)},expression:"distributionItem.product_qty"}})],1)]),(_vm.purchaseOrderOption.has_gift)?_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"font-weight-medium mr-1"},[_vm._v(" Quà tặng ("+_vm._s(_vm.distributionItemGiftsQuantity)+") ")]),(
        _vm.purchaseOrder.status !== 3 &&
          _vm.purchaseOrder.status !== 4 &&
          _vm.purchaseOrder.status !== -1
      )?_c('v-select',{staticClass:"text-body-1 mb-2",attrs:{"flat":"","dense":"","items":_vm.filteredPurchaseOrderOptionGifts,"item-text":"option.product_name","item-value":"id","menu-props":{ nudgeTop: '-28px' },"no-data-text":"Đã chọn hết quà tặng","placeholder":"Chọn quà tặng","hide-details":"auto","return-object":"","single-line":""},on:{"change":function($event){return _vm.selectGift($event)}},model:{value:(_vm.selectedGift),callback:function ($$v) {_vm.selectedGift=$$v},expression:"selectedGift"}}):_vm._e(),_c('ul',{staticClass:"list-gift pl-0"},_vm._l((_vm.distributionItem.gifts),function(item,index){return _c('li',{key:index,staticClass:"list-gift--item d-flex align-end justify-space-between"},[_c('div',{staticClass:"item-name mr-1"},[(
              _vm.purchaseOrder.status !== 3 &&
                _vm.purchaseOrder.status !== 4 &&
                _vm.purchaseOrder.status !== -1
            )?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-icon',{staticClass:"icon-close mr-1",attrs:{"color":hover ? 'grey darken-1' : 'grey lighten-1',"size":"16px"},on:{"click":function($event){return _vm.removeGift(item, index)}}},[_vm._v("mdi-trash-can-outline")])]}}],null,true)}):_vm._e(),_c('span',[_vm._v(_vm._s(((item.option.product_name) + " - " + (item.option.SKU))))])],1),_c('div',{staticClass:"mb-1",staticStyle:{"width":"40px"}},[_c('v-text-field',{staticClass:"text-body-1 text-field__text-right",attrs:{"dense":"","disabled":_vm.purchaseOrder.status === 3 ||
                _vm.purchaseOrder.status === 4 ||
                _vm.purchaseOrder.status === -1,"flat":"","hide-details":"","placeholder":"1000","type":"number"},model:{value:(item.gift_qty),callback:function ($$v) {_vm.$set(item, "gift_qty", $$v)},expression:"item.gift_qty"}})],1)])}),0)],1):_vm._e(),(
      _vm.purchaseOrder.status !== 3 &&
        _vm.purchaseOrder.status !== 4 &&
        _vm.purchaseOrder.status !== -1
    )?_c('div',{staticClass:"btn-distribution--remove text-right mt-2"},[_c('v-btn',{staticClass:"white red--text text--accent-2 text-none mt-2",attrs:{"depressed":"","small":""},on:{"click":_vm.removeDistribution}},[_vm._v(" Xóa ")])],1):_vm._e(),(_vm.purchaseOrder.status === 3)?_c('div',{staticClass:"btn-distribution--remove text-right mt-2"},[_c('v-btn',{staticClass:"white primary--text text-none mt-2",attrs:{"depressed":"","small":"","loading":_vm.purchaseOrderStatusRequest.value ===
          'loading-updatePurchaseOrderDistributionEstimateDate'},on:{"click":function($event){_vm.purchaseOrderStatusRequest.value ===
        'loading-updatePurchaseOrderDistributionEstimateDate'
          ? null
          : _vm.updatePurchaseOrderDistributionEstimateDate()}}},[_vm._v(" Lưu ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }