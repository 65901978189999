<template>
  <v-card class="px-5 pt-4 pb-5" flat>
    <general-info></general-info>
    <template v-if="false">
      <stock-info class="mt-5"></stock-info>
      <selling-status class="mt-3"></selling-status>
      <products-same-segment class="mt-5"></products-same-segment>
      <market class="mt-5"></market>
    </template>
  </v-card>
</template>

<script>
import GeneralInfo from "./components/GeneralInfo";
import Market from "./components/Market";
import ProductsSameSegment from "./components/ProductsSameSegment";
import SellingStatus from "./components/SellingStatus";
import StockInfo from "./components/StockInfo";

export default {
  components: {
    GeneralInfo,
    Market,
    ProductsSameSegment,
    SellingStatus,
    StockInfo
  }
};
</script>
