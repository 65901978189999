var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"card-wrap",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có sản phẩm nào","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":false,"loading-text":"Đang tải dữ liệu","items":_vm.purchaseOrder.options,"item-key":"id"},on:{"click:row":function($event){return _vm.openModalPurchaseOrderProductOptions($event)}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.indexOfOptionIds(item.id) + 1)+" ")]}},{key:"item.option",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.option.product_name)+" ")]),_c('div',{staticClass:"text-subtitle-2 black--text font-weight-light"},[_c('div',{staticClass:"my-1"},[_c('span',[_vm._v("MPN: "),_c('span',{staticClass:"font-weight-bold black--text"},[_vm._v(" "+_vm._s(item.option.MPN ? item.option.MPN : "Không có")+" ")])]),_c('span',{staticClass:"px-2"},[_vm._v("|")]),_c('span',[_vm._v("SKU: "),_c('span',{staticClass:"font-weight-bold black--text"},[_vm._v(_vm._s(item.option.SKU))])])]),_c('div',{staticClass:"grey--text text--darken-2 tag-p__mb-0 mb-1",domProps:{"innerHTML":_vm._s(item.option.name)}}),_c('ul',{staticClass:"gift-list black--text font-weight-light pl-0"},_vm._l((item.gifts),function(gift){return _c('li',{key:gift.id,staticClass:"gift-list--item"},[_c('v-icon',{staticClass:"mr-1 red--text text--accent-1",attrs:{"size":"12px"}},[_vm._v(" mdi-wallet-giftcard ")]),_c('span',[_vm._v(_vm._s(((gift.quantity) + " " + (gift.option.product_name) + " - " + (gift.option.SKU))))])],1)}),0)])])]}},{key:"item.import_price",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatCurrency")(item.import_price)))]),(item.has_vat)?_c('div',{staticClass:"text-subtitle-2 font-weight-medium"},[_vm._v(" (VAT) ")]):_vm._e()]}},{key:"item.rebates",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.calculateRebateTotal(item.rebates)))+" ")]}},{key:"item.last_price",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")((item.import_price - _vm.calculateRebateTotal(item.rebates))))+" ")]),_c('div',{staticClass:"text-subtitle-2 font-weight-medium green--text"},[_vm._v(" "+_vm._s(_vm.calculateProfit(item))+"% ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(((item.import_price - _vm.calculateRebateTotal(item.rebates)) * item.import_qty)))+" ")]}}],null,true)}),(
      _vm.purchaseOrder.status !== 3 &&
        _vm.purchaseOrder.status !== 4 &&
        _vm.purchaseOrder.status !== -1
    )?_c('div',{staticClass:"mx-3"},[_c('v-btn',{attrs:{"block":"","depressed":"","x-large":""},on:{"click":_vm.openModalPurchaseOrderProductOptions}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"grey darken-1"}},[_vm._v("mdi-plus")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }