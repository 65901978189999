<template>
  <div>
    <div class="d-flex align-center justify-space-between mb-1">
      <div class="font-weight-bold">
        Chiết khấu ({{ purchaseOrderOptionRebateTotal | formatCurrency }}/sp)
      </div>
      <v-hover
        v-if="
          purchaseOrder.status !== 3 &&
            purchaseOrder.status !== 4 &&
            purchaseOrder.status !== -1
        "
        v-slot:default="{ hover }"
      >
        <div
          class="btn-rebate--add text-decoration-underline"
          :class="{ 'primary--text': hover }"
          @click="addRebate"
        >
          Thêm
        </div>
      </v-hover>
    </div>
    <v-row class="px-1">
      <v-col
        class="py-1 px-2"
        cols="12"
        xl="6"
        v-for="(item, index) in purchaseOrderOption.rebates"
        :key="index"
      >
        <card-rebate
          :rebate="item"
          :rebate-index="index"
          @removeRebate="removeRebate(item)"
        ></card-rebate>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardRebate from "./CardRebate";

export default {
  components: {
    CardRebate
  },
  data() {
    return {
      rebates: [
        { value: 300000, note: "Mr. Hiếu commit qua email" },
        { value: 100000, note: "Sẽ thanh toán qua NCC" }
      ]
    };
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  computed: {
    purchaseOrder() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrder"];
    },
    purchaseOrderOption() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrderOption"];
    },
    purchaseOrderOptionRebateTotal() {
      return this.$store.getters[
        "PURCHASE_ORDER/purchaseOrderOptionRebateTotal"
      ];
    }
  },
  methods: {
    async addRebate() {
      await this.purchaseOrderOption.rebates.push({
        supplier_id: null,
        brand_id: null,
        value: null,
        note: null
      });

      await this.$store.dispatch(
        "PURCHASE_ORDER/setPurchaseOrderOption",
        this.purchaseOrderOption
      );
    },

    removeRebate(item) {
      this.$emit("removeRebate", item);
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-rebate--add {
  cursor: pointer;
  transition: all 0.4s ease;
}
</style>
