<template>
  <v-hover v-slot:default="{ hover }">
    <v-badge
      class="badge-custom d-block"
      color="none"
      :dot="
        purchaseOrder.status === 3 ||
          purchaseOrder.status === 4 ||
          purchaseOrder.status === -1
      "
      overlap
      offset-x="24px"
      offset-y="45%"
      :value="hover"
    >
      <template v-slot:badge>
        <v-btn
          class="white btn-gift--remove"
          color="grey darken-2"
          icon
          @click="remove()"
        >
          <v-icon size="20px">mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>

      <v-card class="grey lighten-4 px-4 py-3" flat>
        <div class="gift-item d-flex align-center">
          <div class="gift-item--left mr-3" style="width: 50px">
            <v-text-field
              v-model="gift.quantity"
              class="font-weight-medium text-field__text-center"
              :disabled="
                purchaseOrder.status === 3 ||
                  purchaseOrder.status === 4 ||
                  purchaseOrder.status === -1
              "
              flat
              hide-details
              placeholder="10"
              solo
              type="number"
            ></v-text-field>
          </div>
          <div
            class="gift-item--right"
            :title="`${gift.option.product_name} - ${gift.option.SKU}`"
          >
            <div class="gift-text font-weight-medium mb-1">
              {{ gift.option.product_name }}
            </div>
            <div class="gift-text text-subtitle-2 font-weight-light">
              SKU: {{ gift.option.SKU }}
            </div>
          </div>
        </div>
      </v-card>
    </v-badge>
  </v-hover>
</template>

<script>
export default {
  props: {
    gift: {
      type: Object
    },
    giftIndex: {
      type: Number
    }
  },
  computed: {
    purchaseOrder() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrder"];
    },
    purchaseOrderOption() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrderOption"];
    }
  },
  methods: {
    async remove() {
      if (this.gift.id) {
        this.$emit("removeGift", {
          id: this.gift.id
        });
      }

      await this.purchaseOrderOption.gifts.splice(this.rebateIndex, 1);

      await this.$store.dispatch(
        "PURCHASE_ORDER/setPurchaseOrderOption",
        this.purchaseOrderOption
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.badge-custom {
  ::v-deep .v-badge__wrapper {
    z-index: 9;
  }
}
.btn-gift--remove {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.gift-item {
  &--right {
    flex: 1;
    min-width: 0;
    .gift-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
