var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"mr-1"},[_c('span',{staticClass:"text-h6 font-weight-bold"},[_vm._v("Phân bổ hàng về kho ")]),_c('span',{staticClass:"font-italic"},[_vm._v("(Còn "),_c('span',{staticClass:"grey--text text--darken-3 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.remainingDistribution.products)+" sản phẩm ")]),_vm._v(" và "),_c('span',{staticClass:"grey--text text--darken-3 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.remainingDistribution.gifts)+" quà tặng ")]),_vm._v(" chưa phân bổ) ")])]),(_vm.purchaseOrderOption.id && _vm.remainingDistribution.products > 0)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(
          _vm.purchaseOrder.status !== 3 &&
            _vm.purchaseOrder.status !== 4 &&
            _vm.purchaseOrder.status !== -1
        )?_c('div',{staticClass:"btn-distribution--add text-decoration-underline",class:{ 'primary--text': hover },on:{"click":_vm.addDistribution}},[_vm._v(" Thêm ")]):_vm._e()]}}],null,false,2738930219)}):_vm._e()],1),(
      _vm.purchaseOrderOption.id && _vm.purchaseOrderOption.assignments.length > 0
    )?_c('v-row',{staticClass:"px-1 mt-3"},_vm._l((_vm.purchaseOrderOption.assignments),function(item,index){return _c('v-col',{key:index,staticClass:"py-1 px-2",attrs:{"cols":"12","lg":"6","xl":"4"}},[_c('card-distribution',{attrs:{"distribution-item":item,"distribution-item-index":index},on:{"removeDistributionItem":function($event){return _vm.removeDistributionItem($event)}}})],1)}),1):_vm._e(),(!_vm.purchaseOrderOption.id)?[_c('div',{staticClass:"yellow lighten-5 rounded px-2 py-1 mt-3"},[_vm._v(" Bạn cần lưu thông tin nhập hàng trước khi muốn thực hiện phân bổ hàng hóa. ")])]:_vm._e(),(
      _vm.purchaseOrder.status !== 3 &&
        _vm.purchaseOrder.status !== 4 &&
        _vm.purchaseOrder.status !== -1 &&
        _vm.purchaseOrderOption.id
    )?_c('v-btn',{staticClass:"mt-4 btn-text--normal",attrs:{"color":"primary","depressed":"","disabled":_vm.remainingDistribution.products > 0 || _vm.remainingDistribution.gifts > 0,"loading":_vm.purchaseOrderStatusRequest.value ===
        'loading-distributePurchaseOrderOption'},on:{"click":_vm.distributePurchaseOrderOption}},[_vm._v(" Lưu ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }