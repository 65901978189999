<template>
  <div>
    <div class="text-h6 font-weight-bold mb-2">Tình hình bán ra</div>
    <v-card class="grey lighten-4 px-4 py-3" flat>
      <div class="d-flex justify-space-between mb-1">
        <div class="font-weight-medium">Lần nhập đầu tiên:</div>
        <div>21/06/2020</div>
      </div>
      <div class="d-flex justify-space-between mb-1">
        <div class="font-weight-medium">Tổng số bán:</div>
        <div>30</div>
      </div>
      <div class="d-flex justify-space-between mb-1">
        <div class="font-weight-medium">Số bán 7 ngày qua:</div>
        <div>02</div>
      </div>
      <div class="d-flex justify-space-between mb-1">
        <div class="font-weight-medium">Số bán 28 ngày qua:</div>
        <div>08</div>
      </div>
      <div class="d-flex justify-space-between mb-1">
        <div class="font-weight-medium">Số bán 90 ngày qua:</div>
        <div>20</div>
      </div>
      <div class="d-flex justify-space-between">
        <div class="font-weight-medium">Trung bình bán ra:</div>
        <div>3,5 sp/tuần</div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>
