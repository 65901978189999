<template>
  <v-toolbar class="modal-toolbar rounded-0" dark color="primary" height="56px">
    <v-toolbar-title class="text-h6 font-weight-bold pl-0 ml-1">
      Sản phẩm đặt NCC
    </v-toolbar-title>

    <v-toolbar-items
      v-if="
        purchaseOrder.status !== 3 &&
          purchaseOrder.status !== 4 &&
          purchaseOrder.status !== -1
      "
      class="align-center ml-4"
      style="width: 332px"
    >
      <v-autocomplete
        class="text-body-1 rounded-lg mr-4"
        clearable
        dense
        flat
        hide-details
        :items="filterSearchProductOptions"
        item-text="product_name"
        item-value="id"
        :menu-props="{ maxWidth: '332px' }"
        no-data-text="Không có dữ liệu"
        no-filter
        placeholder="Nhập SKU, tên sản phẩm"
        prepend-inner-icon="mdi-magnify"
        return-object
        solo-inverted
        :search-input.sync="productSearchKey"
        @change="selectProductOption($event)"
      >
        <template v-slot:item="data">
          <v-list-item-content :title="data.item.name">
            <v-list-item-title
              class="font-weight-medium"
              v-html="data.item.product_name"
            ></v-list-item-title>
            <div class="grey--text text--darken-2 text-subtitle-2">
              <span class="font-weight-bold">{{ data.item.SKU }} - </span>
              <span class="item-subtitle" v-html="data.item.name"></span>
            </div>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-toolbar-items>

    <v-spacer></v-spacer>

    <v-toolbar-items>
      <v-btn icon dark @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
export default {
  data() {
    return {
      productSearchKey: null
    };
  },
  computed: {
    filterSearchProductOptions() {
      if (this.purchaseOrder.options.length > 0) {
        const arr = this.purchaseOrder.options.map(item => item.option.id);

        return this.searchProductOptions.filter(item => !arr.includes(item.id));
      }
      return this.searchProductOptions;
    },
    purchaseOrder() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrder"];
    },
    purchaseOrderOption() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrderOption"];
    },
    searchProductOptions() {
      return this.$store.getters["PRODUCT_OPTION/searchProductOptions"];
    }
  },
  watch: {
    productSearchKey(val) {
      if (val && val.length > 0) {
        this.$store.dispatch("PRODUCT_OPTION/searchProductOptions", {
          search: val
        });
      }
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch("PURCHASE_ORDER/resetPurchaseOrderOption");

      this.$modal.hide({ name: "modal-purchase-order-product-options" });
    },
    async selectProductOption(option) {
      if (!option || !option.id) return false;

      await this.$store.dispatch("PURCHASE_ORDER/resetPurchaseOrderOption");

      this.purchaseOrderOption.po_id = this.purchaseOrder.id;
      this.purchaseOrderOption.option = option;
      this.purchaseOrderOption.option_id = option.id;

      // Update purchase order option
      await this.$store.dispatch(
        "PURCHASE_ORDER/setPurchaseOrderOption",
        this.purchaseOrderOption
      );

      const obj = JSON.parse(JSON.stringify(this.purchaseOrderOption));

      this.purchaseOrder.options.push(obj);

      // Update active purchase order index
      await this.$store.dispatch(
        "PURCHASE_ORDER/setPurchaseOrderOptionActiveIndex",
        this.purchaseOrder.options.length - 1
      );

      // Update purchase orders
      await this.$store.dispatch(
        "PURCHASE_ORDER/setPurchaseOrder",
        this.purchaseOrder
      );

      this.productSearchKey = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-toolbar {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
}
</style>
