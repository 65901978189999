<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <div class="mr-1">
        <span class="text-h6 font-weight-bold">Phân bổ hàng về kho </span>
        <span class="font-italic"
          >(Còn
          <span class="grey--text text--darken-3 font-weight-bold">
            {{ remainingDistribution.products }} sản phẩm
          </span>
          và
          <span class="grey--text text--darken-3 font-weight-bold">
            {{ remainingDistribution.gifts }} quà tặng
          </span>
          chưa phân bổ)
        </span>
      </div>
      <v-hover
        v-if="purchaseOrderOption.id && remainingDistribution.products > 0"
        v-slot:default="{ hover }"
      >
        <div
          v-if="
            purchaseOrder.status !== 3 &&
              purchaseOrder.status !== 4 &&
              purchaseOrder.status !== -1
          "
          class="btn-distribution--add text-decoration-underline"
          :class="{ 'primary--text': hover }"
          @click="addDistribution"
        >
          Thêm
        </div>
      </v-hover>
    </div>

    <v-row
      v-if="
        purchaseOrderOption.id && purchaseOrderOption.assignments.length > 0
      "
      class="px-1 mt-3"
    >
      <v-col
        class="py-1 px-2"
        cols="12"
        lg="6"
        xl="4"
        v-for="(item, index) in purchaseOrderOption.assignments"
        :key="index"
      >
        <card-distribution
          :distribution-item="item"
          :distribution-item-index="index"
          @removeDistributionItem="removeDistributionItem($event)"
        ></card-distribution>
      </v-col>
    </v-row>
    <template v-if="!purchaseOrderOption.id">
      <div class="yellow lighten-5 rounded px-2 py-1 mt-3">
        Bạn cần lưu thông tin nhập hàng trước khi muốn thực hiện phân bổ hàng
        hóa.
      </div>
    </template>

    <v-btn
      v-if="
        purchaseOrder.status !== 3 &&
          purchaseOrder.status !== 4 &&
          purchaseOrder.status !== -1 &&
          purchaseOrderOption.id
      "
      class="mt-4 btn-text--normal"
      color="primary"
      depressed
      :disabled="
        remainingDistribution.products > 0 || remainingDistribution.gifts > 0
      "
      :loading="
        purchaseOrderStatusRequest.value ===
          'loading-distributePurchaseOrderOption'
      "
      @click="distributePurchaseOrderOption"
    >
      Lưu
    </v-btn>
  </div>
</template>

<script>
import CardDistribution from "./CardDistribution";

export default {
  components: {
    CardDistribution
  },
  data() {
    return {
      removedDistributionList: []
    };
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  computed: {
    purchaseOrder() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrder"];
    },
    purchaseOrderOption() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrderOption"];
    },
    purchaseOrderStatusRequest() {
      return this.$store.getters["PURCHASE_ORDER/statusRequest"];
    },
    remainingDistribution() {
      let distributedProducts = 0,
        distributedGifts = 0,
        giftsQuantity = 0;

      // Calculate distributed products and gifts
      if (this.purchaseOrderOption.assignments.length > 0) {
        this.purchaseOrderOption.assignments.forEach(item => {
          if (item.product_qty) {
            distributedProducts += parseInt(item.product_qty);
          }
          if (item.gifts.length > 0) {
            item.gifts.forEach(gift => {
              if (gift.gift_qty) {
                distributedGifts += parseInt(gift.gift_qty);
              }
            });
          }
        });
      }

      // Calculate gifts quantity
      if (
        this.purchaseOrderOption.has_gift &&
        this.purchaseOrderOption.gifts.length > 0
      ) {
        this.purchaseOrderOption.gifts.forEach(item => {
          if (item.quantity) {
            giftsQuantity += parseInt(item.quantity);
          }
        });
      }

      return {
        products: this.purchaseOrderOption.import_qty
          ? parseInt(this.purchaseOrderOption.import_qty) - distributedProducts
          : 0,
        gifts: giftsQuantity - distributedGifts
      };
    }
  },
  methods: {
    async addDistribution() {
      await this.purchaseOrderOption.assignments.push({
        branch_id: null,
        product_qty: null,
        est_date: null,
        gifts: []
      });

      await this.$store.dispatch(
        "PURCHASE_ORDER/setPurchaseOrderOption",
        this.purchaseOrderOption
      );
    },

    async distributePurchaseOrderOption() {
      const arr = JSON.parse(
        JSON.stringify(this.purchaseOrderOption.assignments)
      );

      arr.map(item => {
        item.gifts = {
          // Check gift quantity
          add: item.gifts.filter(item => !item.id),
          edit: item.gifts.filter(item => item.id),
          delete: item.removedGifts ? item.removedGifts : []
        };
        return item;
      });

      const objectSender = {
        po_option_id: this.purchaseOrderOption.id,
        assignments: {
          add: arr.filter(item => !item.id),
          edit: arr.filter(item => item.id),
          delete: this.removedDistributionList
        }
      };

      // Send request
      await this.$store.dispatch(
        "PURCHASE_ORDER/distributePurchaseOrderOption",
        {
          data: objectSender,
          purchaseOrderOptionId: this.purchaseOrderOption.id
        }
      );
      // Alert
      if (
        this.purchaseOrderStatusRequest.value ===
        "success-distributePurchaseOrderOption"
      ) {
        this.removedDistributionList = [];
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu phân bổ"
          }
        });
      } else if (
        this.purchaseOrderStatusRequest.value ===
        "error-distributePurchaseOrderOption"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.purchaseOrderStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    removeDistributionItem(item) {
      this.removedDistributionList.push(item);
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-distribution--add {
  cursor: pointer;
  transition: all 0.4s ease;
}
</style>
