<template>
  <div>
    <div class="text-h6 font-weight-bold mb-2">Cùng phân khúc (05)</div>
    <div>
      <div class="grey lighten-4 rounded px-4 py-3 mb-2">
        <div class="mb-1">Asus Zenbook 15 AX010UA - Asuszenbook001NS</div>
        <div class="d-flex justify-space-between mb-1">
          <div class="font-weight-medium">Giá nhập gần nhất:</div>
          <div>{{ 18000000 | formatCurrency }}</div>
        </div>
        <div class="d-flex justify-space-between mb-1">
          <div class="font-weight-medium">Giá bán:</div>
          <div>{{ 19000000 | formatCurrency }}</div>
        </div>
        <div class="d-flex justify-space-between">
          <div><span class="font-weight-medium">Sẵn bán:</span> 10</div>
          <div><span class="font-weight-medium">Đang về:</span> 20</div>
          <div>
            <span class="font-weight-medium">Tần suất bán:</span> 5,5 sp/tuần
          </div>
        </div>
      </div>
      <div class="grey lighten-4 rounded px-4 py-3">
        <div class="mb-1">Asus Zenbook 15 AX010UA - Asuszenbook001NS</div>
        <div class="d-flex justify-space-between mb-1">
          <div class="font-weight-medium">Giá nhập gần nhất:</div>
          <div>{{ 18000000 | formatCurrency }}</div>
        </div>
        <div class="d-flex justify-space-between mb-1">
          <div class="font-weight-medium">Giá bán:</div>
          <div>{{ 19000000 | formatCurrency }}</div>
        </div>
        <div class="d-flex justify-space-between">
          <div><span class="font-weight-medium">Sẵn bán:</span> 10</div>
          <div><span class="font-weight-medium">Đang về:</span> 20</div>
          <div>
            <span class="font-weight-medium">Tần suất bán:</span> 5,5 sp/tuần
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  }
};
</script>
