<template>
  <v-hover v-slot:default="{ hover }">
    <v-badge
      class="badge-custom d-block"
      color="none"
      :dot="
        purchaseOrder.status === 3 ||
          purchaseOrder.status === 4 ||
          purchaseOrder.status === -1
      "
      overlap
      offset-x="24px"
      offset-y="45%"
      :value="hover"
    >
      <template v-slot:badge>
        <v-btn
          class="white btn-rebate--remove"
          color="grey darken-2"
          icon
          @click="remove()"
        >
          <v-icon size="20px">mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
      <v-card class="grey lighten-4 pt-2 pb-5 px-4" flat>
        <v-row no-gutters>
          <v-col class="pr-2" cols="4">
            <v-select
              v-model="rebateType"
              class="text-body-1"
              flat
              dense
              :disabled="
                purchaseOrder.status === 3 ||
                  purchaseOrder.status === 4 ||
                  purchaseOrder.status === -1
              "
              :items="rebateTypeList"
              item-text="text"
              item-value="id"
              :menu-props="{ nudgeTop: '-28px' }"
              placeholder="Đối tác"
              hide-details="auto"
              single-line
            ></v-select>
          </v-col>
          <v-col class="pl-2" cols="8">
            <v-autocomplete
              v-if="rebateType === 2"
              v-model="rebate.brand_id"
              class="text-body-1"
              clearable
              dense
              :disabled="
                purchaseOrder.status === 3 ||
                  purchaseOrder.status === 4 ||
                  purchaseOrder.status === -1
              "
              flat
              :items="brands"
              item-text="name"
              item-value="id"
              hide-details
              no-data-text="Không có dữ liệu"
              placeholder="Chọn hãng"
            ></v-autocomplete>
            <v-autocomplete
              v-else
              v-model="rebate.supplier_id"
              class="text-body-1"
              clearable
              dense
              :disabled="
                purchaseOrder.status === 3 ||
                  purchaseOrder.status === 4 ||
                  purchaseOrder.status === -1
              "
              flat
              :items="suppliers"
              item-text="code"
              item-value="id"
              hide-details
              no-data-text="Không có dữ liệu"
              placeholder="Chọn nhà cung cấp"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mt-3" no-gutters>
          <v-col class="pr-2" cols="4">
            <tp-input-price
              custom-class="text-body-1 pt-0"
              dense
              :disabled="
                purchaseOrder.status === 3 ||
                  purchaseOrder.status === 4 ||
                  purchaseOrder.status === -1
              "
              flat
              hide-details
              suffix="₫/sp"
              :value="rebate.value"
              @change="rebate.value = $event"
            ></tp-input-price>
          </v-col>
          <v-col class="pl-2" cols="8">
            <v-text-field
              v-model="rebate.note"
              class="text-body-1"
              flat
              dense
              :disabled="
                purchaseOrder.status === 3 ||
                  purchaseOrder.status === 4 ||
                  purchaseOrder.status === -1
              "
              placeholder="Ghi chú"
              hide-details="auto"
              single-line
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-badge>
  </v-hover>
</template>

<script>
export default {
  props: {
    rebate: {
      type: Object
    },
    rebateIndex: {
      type: Number
    }
  },
  data() {
    return {
      rebateType: 1,
      rebateTypeList: [
        { id: 1, text: "Nhà cung cấp" },
        { id: 2, text: "Hãng" }
      ]
    };
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  computed: {
    brands() {
      return this.$store.getters["BRAND/allBrands"];
    },
    purchaseOrder() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrder"];
    },
    purchaseOrderOption() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrderOption"];
    },
    suppliers() {
      return this.$store.getters["SUPPLIER/allSuppliers"];
    }
  },
  watch: {
    rebateType(val) {
      if (val === 1) {
        this.rebate.brand_id = null;
      }
      if (val === 2) {
        this.rebate.supplier_id = null;
      }
    }
  },
  async created() {
    if (this.brands.length === 0) {
      await this.$store.dispatch("BRAND/getAllBrands");
    }
    if (this.suppliers.length === 0) {
      await this.$store.dispatch("SUPPLIER/getAllSuppliers", "?sup_cate_ids=1");
    }
    if (this.rebate.brand_id) {
      this.rebateType = 2;
    } else {
      this.rebateType = 1;
    }
  },
  methods: {
    async remove() {
      if (this.rebate.id) {
        this.$emit("removeRebate", {
          id: this.rebate.id
        });
      }
      await this.purchaseOrderOption.rebates.splice(this.rebateIndex, 1);

      await this.$store.dispatch(
        "PURCHASE_ORDER/setPurchaseOrderOption",
        this.purchaseOrderOption
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.badge-custom {
  ::v-deep .v-badge__wrapper {
    z-index: 9;
  }
}
.btn-rebate--remove {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>
