<template>
  <v-card class="grey lighten-4 fill-height px-4 py-3" flat>
    <!-- Start: Branch -->
    <div class="d_flex align-end justify-space-between">
      <div class="font-weight-medium mr-1">Mã kho:</div>
      <div class="d-flex align-end">
        <div class="white rounded px-2 mr-2" v-if="selectedBranch">
          <v-icon class="mr-1" color="grey" size="14px">mdi-map-marker</v-icon>
          <span class="text-subtitle-2 font-weight-light">
            {{ selectedBranch.tinh_thanhpho }}
          </span>
        </div>
        <div style="width: 120px">
          <v-select
            v-model="distributionItem.branch_id"
            class="text-body-1"
            flat
            dense
            :disabled="
              purchaseOrder.status === 3 ||
                purchaseOrder.status === 4 ||
                purchaseOrder.status === -1
            "
            :items="branches"
            item-text="name"
            item-value="id"
            :menu-props="{ nudgeTop: '-28px' }"
            no-data-text="Không có dữ liệu"
            placeholder="Chọn kho"
            hide-details="auto"
            single-line
          ></v-select>
        </div>
      </div>
    </div>
    <!-- End: Branch -->
    <!-- Start: Estimate date -->
    <div class="d-flex align-end justify-space-between mt-2">
      <div class="font-weight-medium mr-1">Ngày về dự kiến:</div>
      <div>
        <tp-input-date
          custom-class="text-body-1 mt-0"
          custom-style="width: 80px"
          dense
          :disabled="purchaseOrder.status === 4 || purchaseOrder.status === -1"
          hide-details
          placeholder="Chọn ngày"
          :value="distributionItem.est_date"
          @change="distributionItem.est_date = $event"
        ></tp-input-date>
      </div>
    </div>
    <!-- End: Estimate date -->
    <!-- Start: Goods quantity -->
    <div class="d-flex align-end justify-space-between mt-2">
      <div class="font-weight-medium mr-1">Số lượng hàng:</div>
      <div class="" style="width: 40px">
        <v-text-field
          v-model="distributionItem.product_qty"
          class="text-body-1 text-field__text-right"
          dense
          :disabled="
            purchaseOrder.status === 3 ||
              purchaseOrder.status === 4 ||
              purchaseOrder.status === -1
          "
          flat
          hide-details
          placeholder="1000"
          type="number"
        ></v-text-field>
      </div>
    </div>
    <!-- End: Goods quantity -->
    <!-- Start: Gift quantity -->
    <div class="mt-4" v-if="purchaseOrderOption.has_gift">
      <div class="font-weight-medium mr-1">
        Quà tặng ({{ distributionItemGiftsQuantity }})
      </div>
      <v-select
        v-if="
          purchaseOrder.status !== 3 &&
            purchaseOrder.status !== 4 &&
            purchaseOrder.status !== -1
        "
        v-model="selectedGift"
        class="text-body-1 mb-2"
        flat
        dense
        :items="filteredPurchaseOrderOptionGifts"
        item-text="option.product_name"
        item-value="id"
        :menu-props="{ nudgeTop: '-28px' }"
        no-data-text="Đã chọn hết quà tặng"
        placeholder="Chọn quà tặng"
        hide-details="auto"
        return-object
        single-line
        @change="selectGift($event)"
      >
      </v-select>
      <ul class="list-gift pl-0">
        <li
          class="list-gift--item d-flex align-end justify-space-between"
          v-for="(item, index) in distributionItem.gifts"
          :key="index"
        >
          <div class="item-name mr-1">
            <v-hover
              v-if="
                purchaseOrder.status !== 3 &&
                  purchaseOrder.status !== 4 &&
                  purchaseOrder.status !== -1
              "
              v-slot:default="{ hover }"
            >
              <v-icon
                class="icon-close mr-1"
                :color="hover ? 'grey darken-1' : 'grey lighten-1'"
                size="16px"
                @click="removeGift(item, index)"
                >mdi-trash-can-outline</v-icon
              >
            </v-hover>
            <span>{{
              `${item.option.product_name} - ${item.option.SKU}`
            }}</span>
          </div>
          <div class="mb-1" style="width: 40px">
            <v-text-field
              v-model="item.gift_qty"
              class="text-body-1 text-field__text-right"
              dense
              :disabled="
                purchaseOrder.status === 3 ||
                  purchaseOrder.status === 4 ||
                  purchaseOrder.status === -1
              "
              flat
              hide-details
              placeholder="1000"
              type="number"
            ></v-text-field>
          </div>
        </li>
      </ul>
    </div>
    <!-- End: Gift quantity -->
    <!-- Start: Actions -->
    <div
      v-if="
        purchaseOrder.status !== 3 &&
          purchaseOrder.status !== 4 &&
          purchaseOrder.status !== -1
      "
      class="btn-distribution--remove text-right mt-2"
    >
      <v-btn
        class="white red--text text--accent-2 text-none mt-2"
        depressed
        small
        @click="removeDistribution"
      >
        Xóa
      </v-btn>
    </div>
    <div
      v-if="purchaseOrder.status === 3"
      class="btn-distribution--remove text-right mt-2"
    >
      <v-btn
        class="white primary--text text-none mt-2"
        depressed
        small
        :loading="
          purchaseOrderStatusRequest.value ===
            'loading-updatePurchaseOrderDistributionEstimateDate'
        "
        @click="
          purchaseOrderStatusRequest.value ===
          'loading-updatePurchaseOrderDistributionEstimateDate'
            ? null
            : updatePurchaseOrderDistributionEstimateDate()
        "
      >
        Lưu
      </v-btn>
    </div>
    <!-- End: Actions -->
  </v-card>
</template>

<script>
export default {
  props: {
    distributionItem: {
      type: Object
    },
    distributionItemIndex: {
      type: Number
    }
  },
  data() {
    return {
      selectedGift: null
    };
  },
  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },

    distributionItemGiftsQuantity() {
      let count = 0;

      this.distributionItem.gifts.forEach(item => {
        if (item.gift_qty) {
          count += parseInt(item.gift_qty);
        }
      });

      return count;
    },

    filteredPurchaseOrderOptionGifts() {
      if (this.distributionItem.gifts.length > 0) {
        const arr = this.distributionItem.gifts.map(
          item => item.po_option_gift_id
        );

        return this.purchaseOrderOption.gifts.filter(
          item => !arr.includes(item.id)
        );
      }
      return this.purchaseOrderOption.gifts;
    },

    purchaseOrder() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrder"];
    },

    purchaseOrderOption() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrderOption"];
    },

    purchaseOrderStatusRequest() {
      return this.$store.getters["PURCHASE_ORDER/statusRequest"];
    },

    selectedBranch() {
      if (this.distributionItem.branch_id) {
        return this.branches.find(
          branch => branch.id === this.distributionItem.branch_id
        );
      }
      return null;
    }
  },
  async created() {
    if (this.branches.length === 0) {
      await this.$store.dispatch("BRANCH/getAllBranches");
    }
  },
  methods: {
    async removeDistribution() {
      // Push item into removed list if having id
      if (this.distributionItem.id) {
        this.$emit("removeDistributionItem", {
          id: this.distributionItem.id
        });
      }
      // Remove item
      await this.purchaseOrderOption.assignments.splice(
        this.distributionItemIndex,
        1
      );
      // Update vuex state
      await this.$store.dispatch(
        "PURCHASE_ORDER/setPurchaseOrderOption",
        this.purchaseOrderOption
      );
    },

    removeGift(item, index) {
      if (item.id) {
        if (!Object.prototype.hasOwnProperty.call(item, "removedGifts")) {
          this.distributionItem.removedGifts = [];
        }
        this.distributionItem.removedGifts.push({ id: item.id });
      }

      this.distributionItem.gifts.splice(index, 1);
    },

    selectGift(item) {
      if (!item && !item.id) return false;

      this.distributionItem.gifts.push({
        po_option_gift_id: item.id,
        option: item.option,
        gift_qty: null
      });

      this.selectedGift = null;
    },

    async updatePurchaseOrderDistributionEstimateDate() {
      await this.$store.dispatch(
        "PURCHASE_ORDER/updatePurchaseOrderDistributionEstimateDate",
        {
          po_option_branch_id: this.distributionItem.id,
          date: this.distributionItem.est_date
        }
      );

      if (
        this.purchaseOrderStatusRequest.value ===
        "success-updatePurchaseOrderDistributionEstimateDate"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã cập nhật ngày về dự kiến"
          }
        });
      } else if (
        this.purchaseOrderStatusRequest.value ===
        "error-updatePurchaseOrderDistributionEstimateDate"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.purchaseOrderStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.item-name {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .icon-close {
    cursor: pointer;
    margin-bottom: 3px;
    transition: all 0.4s ease;
  }
}
</style>
